<template>
  <div class="root">
    <van-form @submit="onSubmit">
      <van-field
        v-model="username"
        name="username"
        label="用户名"
        placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="password"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div class="captcha">
        <van-field
          v-model="captchainput"
          name="captcha"
          label=""
          placeholder="验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
        />
        <van-image
          height="43px"
          fit="contain"
          :src="captcha.image"
          @click="getCaptcha"
        />
      </div>
      <div style="margin: 16px">
        <van-button
          :disabled="isDisabled"
          round
          block
          type="info"
          native-type="submit"
          >登录</van-button
        >
      </div>
    </van-form>
    <p class="tishi">
      数据页面只有管理员可以访问，如果您不是管理员，请返回首页！
    </p>
  </div>
</template>

<script>
import md5 from "js-md5";
import { sessionSet } from "@/utils/local.js";
import { getCaptcha_api, loginByUsername_api } from "@/api/user.js";
export default {
  data() {
    return {
      captcha: {}, // 静态验证码
      captchainput: "", // 输入的验证码
      isDisabled: false, // 禁用登录按钮
      username: "",
      password: "",
    };
  },
  methods: {
    onSubmit(values) {
      this.isDisabled = true;
      setTimeout(() => {
        this.isDisabled = false;
      }, 4000);
      this.loginByUsername();
      this.getCaptcha();
    },
    // 用户名登录
    async loginByUsername() {
      let res = await loginByUsername_api({
        tenantId: this.$tenantId,
        username: this.username,
        password: md5(this.password),
        "Captcha-Key": this.captcha.key,
        "Captcha-Code": this.captchainput,
        // grant_type: "password",
        grant_type: "captcha",
        scope: "all",
        type: "account",
      });
      // console.log("LOGIN: ", res);
      // if (res.error) {
      //   return this.$message.error(res.error_description);
      // }
      let token = {
        access_token: res.access_token,
        refresh_token: res.refresh_token,
        token_type: res.token_type,
      };
      sessionSet("tk", token);
      sessionSet("userInfo", res);
      sessionSet("userId", res.user_id);
      this.$store.commit("updateLogin", true);
      this.$store.commit("updateUserInfo", res);
      if (res.role_code == "districtUser") {
        this.$router.replace("/mobile/data");
      } else {
        this.$router.replace("/mobile/index");
      }
    },
    // 获取动态验证码
    async getCaptcha() {
      let res = await getCaptcha_api();
      this.captcha = res;
    },
  },
  created() {
    this.getCaptcha();
  },
};
</script>

<style lang="less" scoped>
.root {
  .captcha{
    position:relative;
    /deep/.van-field__body{
      width:calc(100% - 120px);
    }
    .van-image{
      position:absolute;
      top:0;
      right:0;
    }
  }
  /deep/.van-field__label {
    width: 4.2em;
  }
  .tishi {
    text-align: center;
    padding: 20px 20%;
    font-size: 0.8em;
    line-height: 1.5em;
    color: #f00;
  }
}
</style>
